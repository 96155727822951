// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import apolloClient from 'apolloClient';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import CLIENT_TRAFFIC_USAGE_INTERVALS from 'queries/ClientTrafficUsageIntervals.graphql';
import TRAFFIC_USAGE_BY_CLIENT_DOMAINS_LIST from 'queries/TrafficUsageByClientDomainsList.graphql';
import TOTAL_TRAFFIC_USAGE_BY_CLIENT_DOMAINS from 'queries/TotalTrafficUsageByClientDomains.graphql';

export const trafficUsageByClientDomainsRoute = ({
    clientIdParamName,
    clientType,
}) => ({
    index: true,
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/TrafficUsageByDomainsPage" */
        'components/pages/TrafficUsageByDomainsPage'
    ),
    loader: async ({ params }) => {
        const clientId = params[clientIdParamName];
        const trafficUsageListKey = `TrafficUsageByClientDomainsList${clientId}`;
        const clientNodeId = btoa(`Client:${clientId}`);

        const [{ data: intervalsData }] = await Promise.all([
            apolloClient.query({
                query: CLIENT_TRAFFIC_USAGE_INTERVALS,
                variables: { clientId: clientNodeId },
            }),
            apolloClient.query({
                query: TOTAL_TRAFFIC_USAGE_BY_CLIENT_DOMAINS,
                variables: { clientId: clientNodeId },
            }),
            apolloClient.query({
                query: TRAFFIC_USAGE_BY_CLIENT_DOMAINS_LIST,
                variables: {
                    clientId: clientNodeId,
                    input: readGraphQlListInputFromStorage({ key: trafficUsageListKey }),
                },
            }),
        ]);

        const { trafficUsageIntervals } = intervalsData.client.statistics;

        return {
            trafficUsageIntervals,
            listQueryParams: {
                key: trafficUsageListKey,
                query: TRAFFIC_USAGE_BY_CLIENT_DOMAINS_LIST,
                extractList: data => data.client.statistics.trafficUsageByDomains,
                variables: { clientId: clientNodeId },
            },
            totalUsageQueryParams: {
                query: TOTAL_TRAFFIC_USAGE_BY_CLIENT_DOMAINS,
                variables: { clientId: clientNodeId },
                extractTrafficData: data => data.client.statistics.totalTrafficUsageByDomains,
            },
            showFilters: clientType !== 'customer',
            showClientNameColumn: clientType !== 'customer',
            parentClientType: clientType,
        };
    },
});
