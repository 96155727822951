// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';
import { redirect } from 'react-router-dom';
import { summaryReportRoute, summaryReportChildrenRoute } from './summaryReportRoute';
import { resourceUsageByDomainRoute } from './resourceUsageByDomainRoute';
import { trafficUsageByViewerCustomersRoute, trafficUsageByViewerCustomersChildrenRoute } from 'routes/trafficUsageByViewerCustomersRoute';
import { trafficUsageByViewerResellersRoute, trafficUsageByViewerResellersChildrenRoute } from 'routes/trafficUsageByViewerResellersRoute';
import { trafficUsageByViewerDomainsRoute, trafficUsageByViewerDomainsChildrenRoute } from 'routes/trafficUsageByViewerDomainsRoute';
export const infoAndStatisticsRoute = {
    path: 'info-and-statistics',
    loader: async () => {
        const { data } = await apolloClient.query({
            query: require('queries/InfoAndStatisticsPage.graphql'),
        });

        return data;
    },
    handle: {
        crumb: ({ data: { viewer: { type } } }) => ({
            title: Locale.getSection('components.routes').lmsg(type === 'ADMIN' ? 'infoAndStatisticsAdmin' : 'infoAndStatisticsReseller'),
        }),
    },
    children: [{
        componentLoader: () => import(
            /* webpackChunkName: "components/pages/InfoAndStatisticsPage" */
            'components/pages/InfoAndStatisticsPage'
        ),
        children: [
            {
                index: true,
                loader: async () => {
                    const { data: { viewer: { permissions: { manageServerInfo } } } } = await apolloClient.query({
                        query: require('queries/InfoAndStatisticsPage.graphql'),
                    });

                    if (manageServerInfo) {
                        return redirect('overview');
                    }
                    return redirect('domains');
                },
            },
            {
                path: 'overview',
                componentLoader: () => import(/* webpackChunkName: "components/pages/ServerInformationPage" */'components/pages/ServerInformationPage'),
                loader: async () => {
                    const { data: { server: { information } } } = await apolloClient.query({
                        query: require('queries/ServerInformationPage.graphql'),
                    });

                    if (!information) {
                        throw new Response('Not Found', { status: 404 });
                    }
                },
                handle: {
                    helpContext: 'server.statistics',
                },
            },
            {
                path: 'traffic-usage',
                loader: async () => {
                    const { data } = await apolloClient.query({
                        query: require('queries/ViewerType.graphql'),
                    });
                    return data;
                },
                componentLoader: () => import(
                    /* webpackChunkName: "components/pages/InfoAndStatisticsPage/TrafficUsageLayoutPage" */
                    'components/pages/InfoAndStatisticsPage/TrafficUsageLayoutPage'
                ),
                children: [
                    {
                        index: true,
                        loader: () => redirect('./domains'),
                    },
                    trafficUsageByViewerDomainsRoute({ path: 'domains', ignoreTopLevelTitle: true }),
                    trafficUsageByViewerCustomersRoute({ path: 'customers', ignoreTopLevelTitle: true }),
                    trafficUsageByViewerResellersRoute({ path: 'resellers', ignoreTopLevelTitle: true }),
                ],
            },
            resourceUsageByDomainRoute({ path: 'domains', ignoreTopLevelTitle: true }),
            summaryReportRoute({ ignoreTopLevelTitle: true }),
        ],
    },
    summaryReportChildrenRoute,
    trafficUsageByViewerDomainsChildrenRoute({ path: 'traffic-usage/domains' }),
    trafficUsageByViewerCustomersChildrenRoute({ path: 'traffic-usage/customers' }),
    trafficUsageByViewerResellersChildrenRoute({ path: 'traffic-usage/resellers' }),
    ],
};
