// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';
import TRAFFIC_USAGE_BY_DOMAIN_PAGE from 'queries/TrafficUsageByDomainPage.graphql';
import DOMAIN_TRAFFIC_USAGE from 'queries/DomainTrafficUsage.graphql';

export const trafficUsageByDomainRoute = ({ path }) => ({
    path,
    handle: {
        crumb: ({ data }) => ({
            title: Locale.getSection('components.routes').lmsg(
                'trafficUsageByDomain',
                { domainName: `<b>${data.domain.name}</b>` },
            ),
        }),
    },
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/TrafficUsageByDomainPage" */
        'components/pages/TrafficUsageByDomainPage'
    ),
    loader: async ({ params }) => {
        const [{ data: pageData }] = await Promise.all([
            apolloClient.query({
                query: TRAFFIC_USAGE_BY_DOMAIN_PAGE,
                variables: { domainId: btoa(`Domain:${params.domainId}`) },
            }),
            apolloClient.query({
                query: DOMAIN_TRAFFIC_USAGE,
                variables: { domainId: btoa(`Domain:${params.domainId}`) },
            }),
        ]);

        return {
            domain: pageData.domain,
            intervals: pageData.domain.statistics.trafficUsageIntervals,
        };
    },
});
